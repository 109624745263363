const state = {
  snackbar: {
    show: false,
    color: null,
    text: null
  },
  connectivityProbe: {
    internet: true
  }
}

const getters = {
  connectivityProbe: state => state.connectivityProbe,
  snackbar: state => state.snackbar
}

const actions = {
  updateConnectivityProbe: (context, payload) => context.commit('SET_CONNECTIVITY_PROBE', payload),
  showSnackbar: (context, payload) => context.commit('SET_SNACKBAR', payload)
}

const mutations = {
  SET_SNACKBAR: (state, snackbar) => Object.assign(state.snackbar, snackbar, { show: true }),
  SET_CONNECTIVITY_PROBE: (state, data) => Object.assign(state.connectivityProbe, data)
}

export default {
  state,
  getters,
  actions,
  mutations
}
