<template>
  <v-container>
    <v-row v-for="(item, i) in aboutData" :key="i" class="partners py-5 px-15" :style="i & 1 ? 'flex-direction: row-reverse' : ''">
      <v-col md="6" class="px-0">
        <img :src = item.image alt="Image">
      </v-col>
      <v-col md="6" class="px-0">
        <v-card class="mx-auto px-15 py-5 pt-1" flat>
          <v-list-item-title class="headline mb-1">{{ item.title }}</v-list-item-title>
          <v-list-item-subtitle class="body">{{ item.body }}</v-list-item-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Partners',
  data: () => {
    return {
      aboutData: [
        {
          title: 'About Us',
          image: require('@/assets/about/1.png'),
          body: 'Lacus, quis arcu nibh id et phasellus ut elementum. Lobortis sodales commodo amet facilisi cras velit hendrerit praesent ultrices. At metus ac.'
        },
        {
          title: 'Programmes',
          image: require('@/assets/about/2.png'),
          body: 'Lacus, quis arcu nibh id et phasellus ut elementum. Lobortis sodales commodo amet facilisi cras velit hendrerit praesent ultrices. At metus ac.'
        },
        {
          title: 'Registration',
          image: require('@/assets/about/3.png'),
          body: 'Lacus, quis arcu nibh id et phasellus ut elementumr. Lobortis sodales commodo amet facilisi cras velit hendrerit praesent ultrices. At metus ac.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
 .partners {
    display: flex;
    align-items: flex-start;

    p {
      text-align: center;
      width: 100%
    }
    img {
        width: inherit;
    }
    .body {
      white-space: pre-wrap;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
    }
 }
</style>
