<template>
  <v-app id="open-institute">
    <Navigation />
    <v-main id="main">
      <router-view/>
    </v-main>
    <Footer :snackbar="snackbar" />
  </v-app>
</template>

<script>
import Navigation from '@/components/Navigation'
import Footer from '@/components/Footer'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    Navigation,
    Footer
  },
  computed: {
    ...mapGetters(['snackbar'])
  }
}
</script>
