<template>
  <v-row class="updates">
    <v-col cols md="6" class="first px-10">
      <p class="display-1" style="color:#F04E30">Subscribe to Our Updates</p>
      <p class="subtitle-2 text--primary">The bi-weekly update provide details of students exchange activities in the East Africa region)</p>
    </v-col>
    <v-col cols md="6" class="second">
      <v-text-field
        outlined
        clearable
        label="Your email here"
      ></v-text-field>
      <v-btn rounded large color="#0CBAB4">Download</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Updates'
}
</script>

<style lang="scss" scoped>
 .updates {
    background: #5c667059;
    padding: 60px 30px;
    justify-content: center;

  .first {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
  }
  .second {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 26px;
        display: flex;
    align-items: baseline;
  }
 }
</style>
