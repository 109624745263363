<template>
  <div class="signup">
    <v-row class="mt-0">
      <v-col cols md="6" sm="6" class="px-16 py-0 auth">
        <v-container class="px-5">
          <v-card class="mx-auto px-15 py-7" flat>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">Sign In</v-list-item-title>
                <v-list-item-subtitle class="mb-4">Insert your credentials below to log into the system</v-list-item-subtitle>

                <v-text-field
                  outlined
                  clearable
                  label="Full Name"
                  prepend-inner-icon="mdi-account"
                  :rules="[rules.required, rules.min, rules.emailMatch]"
                ></v-text-field>

                <v-text-field
                  outlined
                  clearable
                  label="Registration Number"
                  prepend-inner-icon="mdi-account"
                  :rules="[rules.required, rules.min, rules.emailMatch]"
                ></v-text-field>

                <v-text-field
                  outlined
                  clearable
                  label="Email address"
                  prepend-inner-icon="mdi-account"
                  :rules="[rules.required, rules.min, rules.emailMatch]"
                ></v-text-field>

                <v-text-field dense
                  prepend-inner-icon="mdi-lock"
                  outlined
                  clearable
                  :append-icon="peek ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="peek ? 'text' : 'password'"
                  label="Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="peek = !peek"
                ></v-text-field>

                <v-text-field dense
                  prepend-inner-icon="mdi-lock"
                  outlined
                  clearable
                  :append-icon="peek2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="peek2 ? 'text' : 'password'"
                  label="Confirm Password"
                  hint="At least 8 characters"
                  counter
                  @click:append="peek2 = !peek2"
                ></v-text-field>

                <v-list-item-subtitle class="mb-4 sign-more">
                  <router-link class="mb-4" to="/signin">Have an account ? Login</router-link>
                </v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn rounded dark class="px-10">Sign Up</v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols md="6" sm="6" class="signup-img"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Programmes',
  data: () => {
    return {
      peek: false,
      peek2: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('The email and password you entered don\'t match')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth {
  display: flex;
    align-items: center;
    .v-input {
      width: 100%
    }
}
  .sign-more {
    .v-divider {
      border-color: inherit;height: 18px;
    }
    * {
      text-decoration: none;
    }
  }
  .signup-img {
    background: url('../assets/SignUp.png');
    background-size: 100% 100%;
    min-height: 600px;
  }
</style>
