<template>
  <v-row class="whyjoin">
    <v-col cols md="2" class="first">Why Join?</v-col>
    <v-col cols md="6" class="second">Pretium lectus non purus nunc interdum. Nunc malesuada at egestas in sed. Lectus nulla tempor donec tempus metus semper tempor. </v-col>
    <v-col cols md="2" class="third"><router-link to="/signup"><v-btn rounded dark class="mx-2">Register</v-btn></router-link></v-col>
  </v-row>
</template>

<script>
export default {
  name: 'WhyJoin'
}
</script>

<style lang="scss" scoped>
 .whyjoin {
    background: #E5E5E5;
    padding: 60px 30px;
    justify-content: center;

  .first {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 33px;
  }
  .second {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 26px;
  }
  .third {
    & * {
      text-transform: none;
      text-decoration: none;
    }
  }
 }
</style>
