<template>
  <v-footer id="Footer" app absolute>

    <div class="text-center container pt-5">
      <v-row>
        <v-col cols md="2" sm="2" class="ma-auto">
          <v-avatar color="primary" size="128"></v-avatar>
        </v-col>

        <v-col cols md="3" sm="3" class="links">
          <v-list-item><span class="mx-2 title">Quick Links</span></v-list-item>
          <v-list-item v-for="(route, i) in routes" :key="i">
            <router-link :to="route.path"><span class="mx-2">{{ route.name }}</span></router-link>
          </v-list-item>
        </v-col>

        <v-col cols md="3" sm="3" class="links">
          <v-list-item><span class="mx-2 title">Contact Us</span></v-list-item>
          <v-list-item v-for="(contact, i) in contacts" :key="i">
          <v-list-item-icon><v-icon v-text="contact.icon"></v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-html="contact.value"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        </v-col>

        <v-col cols md="4" sm="4" class="links">
          <v-list-item><span class="mx-2 title">Connect</span></v-list-item>
          <div class="socials">
            <v-list-item v-for="(item, i) in connections" :key="i">
              <a :href="`https://${item.type}.com/${item.value}`" target="_blank">
                <v-list-item-icon class="ma-0"><v-icon size="50" v-text="item.icon"></v-icon></v-list-item-icon>
              </a>
            </v-list-item>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="text-center container pt-5">
      <span class="caption"> &copy; {{ new Date().getFullYear() }} &nbsp;Open Institute</span>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" timeout="3500">
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="$store.dispatch('closeSnackbar')">Close</v-btn>
      </template>
    </v-snackbar>
  </v-footer>
</template>

<script>
import { routes as _routes } from '../router'
export default {
  name: 'Footer',
  props: ['snackbar'],
  data: () => {
    return {
      routes: [],
      contacts: [
        { type: 'Email', value: 'info@e-club.oiu.com', icon: 'mdi-email' },
        { type: 'Phone', value: '+254-123456789', icon: 'mdi-phone' },
        { type: 'Location', value: '22 Makowock Street,\nNairobi, Kenya', icon: 'mdi-map-marker' }
      ],
      connections: [
        { type: 'facebook', value: 'oiu_environmental_club', icon: 'mdi-facebook' },
        { type: 'instagram', value: 'oiu_environmental_club', icon: 'mdi-instagram' },
        { type: 'twitter', value: 'oiu_environmental_club', icon: 'mdi-twitter' },
        { type: 'linkedin', value: 'oiu_environmental_club', icon: 'mdi-linkedin' }
      ]
    }
  },
  mounted () {
    this.routes = _routes.filter(o => !o.path.includes('sign'))
  }
}
</script>

<style lang="scss" scoped>
#Footer {
  bottom: -10px !important;
  margin: auto;
  z-index: 10;

  span {
    a {
      text-decoration: none;
    }
  }

  .links {
    text-align: left;
    .v-list-item {
      line-height: 1.4;
      .v-list-item__title {
      white-space: pre-line;
      }
      .v-list-item__content {
        padding: 5px 0;
      }
      min-height: 30px;
      a {
        text-decoration: none;
      }
    }
    .socials {
      .v-list-item {
        display: inline;
        color: red;
      }
    }
  }
}
</style>
