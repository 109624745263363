<template>
  <v-row class="partners py-5 px-5">
    <p class="display-1 text--primary">Our Partners</p>
    <v-col md="6" class="px-0">
      <img :src = img1 alt="Image">
    </v-col>
    <v-col md="6" class="px-0">
      <img :src = img2 alt="Image">
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Partners',
  data: () => {
    return {
      img1: require('@/assets/partners/1.png'),
      img2: require('@/assets/partners/2.png')
    }
  }
}
</script>

<style lang="scss" scoped>
 .partners {
   p {
     text-align: center;
     width: 100%
   }
   img {
      width: inherit;
   }
 }
</style>
