<template>
  <div class="programmes">
    <div class="overlay flex">
      <span class="mx-2">Programmes</span>
    </div>
    <v-container>

    <v-row class="details">
      <v-col text-align="center" class="one" cols="6" lg="6" md="6" sm="6" style="margin-top: 7rem;
    padding: 5rem 4rem .5rem;">
        <p style="    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 23px;
    line-height: 28px;
    color: #000;">
          Pretium lectus non purus nunc interdum. Nunc malesuada at egestas in sed. Lectus nulla
          tempor donec tempus metus semper tempor. Aliquam, vulputate quam habitant convallis
          morbi nec aenean pharetra neque. Non sit elementum nulla facilisis commodo quisque
          proin mauris. Lobortis risus id elementum.
        </p>
      </v-col>

      <v-col text-align="center" class="two" cols="6" lg="6" md="6" sm="6">
        <div class="video">
          <video width="100%" controls>
            <source :src="videoMp4" type="video/mp4">
            <source :src="videoOgg" type="video/ogg">
            Your browser does not support HTML video.
          </video>
        </div>
      </v-col>
  </v-row>

 <v-timeline dense class="mb-10">
      <v-timeline-item v-for="n in 5" :key="n">
        <span slot="opposite">Tus eu perfecto</span>
        <v-card class="elevation-2">
          <v-card-title class="headline">Lorem ipsum</v-card-title>
          <v-card-text class="program">
            Lorem ipsum dolor sit amet, no nam oblique veritus. Commune scaevola imperdiet nec ut, sed euismod convenire principes at. Est et nobis iisque percipit, an vim zril disputando voluptatibus, vix an salutandi sententiae.
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>

    <v-row class="mb-10">
      <ul class="activities">
        <span class="mx-2 title mb-4 head">Programme Activities</span>
        <li v-for="n in 22" :key="n"><v-icon class="mr-3">mdi-star</v-icon>Activity Here</li>
      </ul>
    </v-row>

  </v-container>

      <v-row class="register">
        <v-col cols md="2" class="first">Why Join?</v-col>
        <v-col cols md="6" class="second">Pretium lectus non purus nunc interdum. Nunc malesuada at egestas in sed. Lectus nulla tempor donec tempus metus semper tempor. </v-col>
        <v-col cols md="2" class="third"><router-link to="/signup"><v-btn rounded dark class="mx-2">Register</v-btn></router-link></v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  name: 'Programmes',
  data: () => {
    return {
      videoOgg: require('@/assets/video.ogg'),
      videoMp4: require('@/assets/video.mp4')
    }
  }
}
</script>

<style lang="scss" scoped>
.details {
      position: relative;
    top: -10rem;
    margin-bottom: -90px;
    .video {
      width: 96%;
    height: auto;
    margin: auto;
    }
}

 .overlay {
   margin-bottom: 16px;
    height: 242px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background: url('../assets/Rectangle.png');
    background-size: 100% 100%;
    span {
      font-size: 40px;
      padding: 0 10rem;
      line-height: 47px;
    }
  }
  .head{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .activities {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 33.3333%;
    display: flex;
    justify-content: center;
    }
  }
  .program {
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;

color: #000000;
  }
  .register {
    background: #E5E5E5;
        padding: 60px 30px;
        justify-content: center;
        .first {
          font-family: Roboto;
font-style: normal;
font-weight: bold;
font-size: 28px;
line-height: 33px;
        }
        .second {
          font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 23px;
line-height: 26px;
        }
        .third {
          & * {
            text-transform: none;
            text-decoration: none;
          }
        }
  }
</style>
