import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import About from '../views/About'
import Programmes from '../views/Programmes'
import Cohorts from '../views/Cohorts'
import Gallery from '../views/Gallery'
import SignUp from '../views/SignUp'
import SignIn from '../views/SignIn'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/about',
    name: 'About',
    component: About
  }, {
    path: '/programmes',
    name: 'Programmes',
    component: Programmes
  }, {
    path: '/cohorts',
    name: 'Cohorts',
    component: Cohorts
  }, {
    path: '/gallery',
    name: 'Gallery',
    component: Gallery
  }, {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  }, {
    path: '/signin',
    name: 'SignIn',
    component: SignIn
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
