<template>
  <div class="signup">
    <v-row class="mt-0">
      <v-col cols md="6" sm="6" class="px-16 py-0 auth">
        <v-container class="px-5">
          <v-card class="mx-auto px-15 py-5" flat>
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="headline mb-1">Sign In</v-list-item-title>
                <v-list-item-subtitle class="mb-4">Insert your credentials below to log into the system</v-list-item-subtitle>

                <v-text-field outlined clearable :rules="[rules.required, rules.min, rules.emailMatch]"
                  label="Email address" prepend-inner-icon="mdi-account"
                ></v-text-field>

                <v-text-field outlined clearable counter :rules="[rules.required, rules.min]"
                  prepend-inner-icon="mdi-lock"  :append-icon="peek ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="peek ? 'text' : 'password'"
                  label="Password" hint="At least 8 characters"
                  @click:append="peek = !peek"
                ></v-text-field>

                <v-list-item-subtitle class="mb-4 sign-more">
                  <router-link class="mb-4" to="#">Forgot Password?</router-link>
                  <v-divider class="mx-4" vertical></v-divider>
                  <router-link class="mb-4" to="/signup">Register</router-link>
                </v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>

            <v-card-actions>
              <v-btn rounded dark class="px-10">Sign In</v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-col>
      <v-col cols md="6" sm="6" class="signup-img"></v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Programmes',
  data: () => {
    return {
      peek: false,
      rules: {
        required: value => !!value || 'Required.',
        min: v => v.length >= 8 || 'Min 8 characters',
        emailMatch: () => ('The email and password you entered don\'t match')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.auth {
  display: flex;
    align-items: center;
    .v-input {
      width: 100%
    }
}
  .sign-more {
    .v-divider {
      border-color: inherit;height: 18px;
    }
    * {
      text-decoration: none;
    }
  }
  .signup-img {
    background: url('../assets/SignUp.png');
    background-size: 100% 100%;
    min-height: 643px;
  }
</style>
