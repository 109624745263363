<template>
  <div id="Navigation">
    <v-app-bar app style="z-index: 10;" class="px-10" flat clipped-right color="transparent" elevate-on-scroll>
        <router-link to="/" @click.native="init" class="d-flex align-center">
          <v-avatar class="mr-2" color="logo grey darken-1" size="48"><img :src = logo alt="logo"></v-avatar>
        </router-link>

        <v-spacer></v-spacer>

        <div class="desktop-nav">
          <router-link v-for="(route, i) in routes" :key="i" :to="route.path">
            <span class="mx-2">{{ route.name }}</span>
          </router-link>
        </div>

        <v-spacer></v-spacer>

        <div class="d-flex align-center">
          <router-link to="/signin"><v-btn rounded dark class="mx-2">Sign In</v-btn></router-link>
          <router-link to="/signup"><v-btn rounded dark class="mx-2">Register</v-btn></router-link>
        </div>
    </v-app-bar>
  </div>
</template>

<script>
import { routes as _routes } from '../router'
export default {
  name: 'Navigation',
  data: () => {
    return {
      drawer: null,
      routes: [],
      logo: require('@/assets/logo.png')
    }
  },
  mounted () {
    this.routes = _routes.filter(o => !o.path.includes('sign'))
  }
}
</script>

<style lang="scss" scoped>
  #Navigation {
    & * {
      text-transform: none;
      text-decoration: none;

      .desktop-nav {
        & a > span {
          font-size: 18px;
        }
      }
    }
  }
</style>
