<template>
  <div class="programmes">
    <div class="overlay flex">
      <span class="mx-2" v-html="`Unlock Regional Career and\nEntrepreneurship Opportunities`"></span>
    </div>
    <v-container>

    <v-row class="carousel">
      <v-col text-align="center" class="two" cols>
         <carousel-3d
            v-bind:controlsVisible="true"
            v-bind:disable3d="true"
            v-bind:autoplay="true"
            animationSpeed="1000"
            v-bind:count="8"
            display="10"
            height="300"
            space="280"
            width="240"
            border="0"
          >
            <slide v-for="(n, index) in 10" :index="index" :key="index">
              <v-sheet rounded elevation="15" height="300" width="240">
                <img :src = getSliderImg(index) alt="Image">
              </v-sheet>
            </slide>
          </carousel-3d>
      </v-col>
    </v-row>

    <v-row class="mb-10">
       <v-col text-align="center" class="two" cols></v-col>
    </v-row>
  </v-container>

    <AboutUs />
    <WhyJoin />
    <Bronchure />
    <Partners />
    <Updates />
  </div>
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d'
import Bronchure from '../components/Bronchure'
import Partners from '../components/Partners'
import WhyJoin from '../components/WhyJoin'
import Updates from '../components/Updates'
import AboutUs from '../components/AboutUs'
export default {
  name: 'Programmes',
  components: {
    Carousel3d,
    Bronchure,
    Partners,
    WhyJoin,
    Updates,
    AboutUs,
    Slide
  },
  data: () => {
    return {
      videoOgg: require('@/assets/video.ogg'),
      videoMp4: require('@/assets/video.mp4')
    }
  },
  methods: {
    getSliderImg: i => require(`@/assets/carousel/${i}.png`)
  }
}
</script>

<style lang="scss" scoped>
.carousel {
      position: relative;
    top: -12rem;
    margin-bottom: -130px;
        justify-content: center;
    .video {
      width: 96%;
    height: auto;
    margin: auto;
    }
}

 .overlay {
   margin-bottom: 16px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url('../assets/Hero.png');
    background-size: 100%;
    span {
      white-space: pre-line;
      font-size: 40px;
      line-height: 47px;
      text-align: center;
      color: #fff;
      width: 100%;
    height: 100%;
    background: #00000080;
    display: flex;
    align-items: center;
    justify-content: center;
    }
  }
  .head{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .activities {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 33.3333%;
    display: flex;
    justify-content: center;
    }
  }
  .program {
    font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 24px;

color: #000000;
  }
</style>
